import React from 'react';
import { Button } from 'antd';
import { GoogleSVG } from 'assets/svg/icon';

const GoogleLoginButton = ({
  isSignedIn,
  handleGoogleLogin,
  handleGoogleLogout,
}) => {
  return !isSignedIn ? (
    <Button
      onClick={handleGoogleLogin}
      style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
    >
      <GoogleSVG />
      Entrar com Google
    </Button>
  ) : (
    <Button
      onClick={handleGoogleLogout}
      style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
    >
      <GoogleSVG />
      Fazer logout
    </Button>
  );
};

export default GoogleLoginButton;
