import { useState, useEffect } from 'react';
import axios from 'axios';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';

const useGoogleLoginHook = () => {
  const [isSignedIn, setIsSignedIn] = useState(
    localStorage.getItem('token') ?? false
  );

  // const [calendarList, setCalendarList] = useState([]);

  // const getCalendarList = async accessToken => {
  //   const userInfo = await axios
  //     .get('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
  //       headers: { Authorization: `Bearer ${accessToken}` },
  //     })
  //     .then(res => res.data);

  //   setCalendarList(userInfo?.items);
  //   await getEventList(accessToken);
  // };

  // const getEventList = async accessToken => {
  //   const userInfo = await axios
  //     .get(
  //       `https://www.googleapis.com/calendar/v3/calendars/gabrieldolmayan@gmail.com/events?timeMin=2023-07-17T00:00:00Z`,
  //       {
  //         headers: { Authorization: `Bearer ${accessToken}` },
  //       }
  //     )
  //     .then(res => res.data);

  //   console.log(userInfo);
  // };

  // useEffect(() => {
  //   if (isSignedIn) {
  //     getCalendarList(localStorage.getItem('token'));
  //   }
  // }, [isSignedIn]);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      setIsSignedIn(tokenResponse.access_token);
      localStorage.setItem('token', tokenResponse.access_token);
      // const userInfo = await getCalendarList(tokenResponse.access_token);
    },
    onError: error => {
      console.log(error);
    },
    scope: 'https://www.googleapis.com/auth/calendar',
  });

  const handleGoogleLogout = () => {
    googleLogout();
    setIsSignedIn(false);
    localStorage.removeItem('token');
  };

  return {
    isSignedIn,
    handleGoogleLogin,
    handleGoogleLogout,
  };
};

export default useGoogleLoginHook;
