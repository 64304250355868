import React, { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import { DIR_RTL } from 'constants/ThemeConstant';
import { useTheme } from 'stores/theme.store';
import NavProfile from './NavProfile';
import NavNotification from './NavNotification';

const NavPanel = () => {
  return (
    <>
      <NavProfile />
    </>
  );
};

export default NavPanel;
