import { UsuarioInterfaceFilter } from './../types/usuario.interface';
import fetch from 'auth/FetchInterceptor';
import { UsuarioInterfacePatch, UsuarioInterfacePost, UsuarioServiceInterface } from "types/usuario.interface";

const getAllUsuarios = async () => {
    const data = await fetch({
        url: `/usuario`,
        method: 'get',
    });
    return data.data;
};

const findUsuarioById = async (uuid: string) => {
    const data = await fetch({
        url: `/usuario/${uuid}`,
        method: 'get',
    });
    return data.data;
};

const findUsuarioByName = async (name: UsuarioInterfaceFilter) => {
    const result = await fetch({
        url: `/usuario/filter?name=${name}`,
        method: 'get',
    });
    return result.data;
};

const createUsuario = async (data: UsuarioInterfacePost) => {
    const result = await fetch({
        url: `/usuario`,
        method: 'post',
        data,
    });
    return result.data;
};

const updateUsuario = async (data: UsuarioInterfacePatch) => {
    const result = await fetch({
        url: `/usuario/${data.uuid}`,
        method: 'patch',
        data,
    });
    return result.data;
};

const deleteUsuario = async (uuid: string) => {
    const result = await fetch({
        url: `/usuario/${uuid}`,
        method: 'delete'
    })
    return result.data;
}

const pessoaService: UsuarioServiceInterface = {
    getAllUsuarios,
    findUsuarioById,
    createUsuario,
    updateUsuario,
    deleteUsuario,
    findUsuarioByName,
};

export default pessoaService;