import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { UsuarioInterface, UsuarioInterfacePost, UsuarioInterfacePatch, UsuarioInterfaceFilter } from 'types/usuario.interface';
import usuarioService from 'services/usuario.service';

interface UsuarioState {
  loadingUsuarios: boolean;
  usuario: UsuarioInterface | null;
  usuarios: UsuarioInterface[];
  countUsuarios: number;
  errorUsuarios: any;
  getUsuarios(): Promise<any>;
  findUsuarioByName(name: UsuarioInterfaceFilter): Promise<void>;
  findUsuarioById(uuid: string): Promise<void>;
  createUsuario(data: UsuarioInterfacePost): Promise<any>;
  updateUsuario(data: UsuarioInterfacePatch): Promise<any>;
  deleteUsuario(uuid: string): Promise<void>;
}

export const useUsuario = create(
  subscribeWithSelector<UsuarioState>((set, get) => ({
    loadingUsuarios: false,
    errorUsuarios: null,
    usuarios: [],
    countUsuarios: 0,
    usuario: null,
    getUsuarios: async () => {
      set({ loadingUsuarios: true });

      try {
        const usuariosResult = await usuarioService.getAllUsuarios();
        set({
          usuarios: usuariosResult,
        });
      } catch (e) {
        set({
          usuarios: [],
          errorUsuarios: e,
        });
      } finally {
        set({ loadingUsuarios: false });
      }
    },
    findUsuarioById: async (uuid: string) => {
      set({ loadingUsuarios: true });
      try {
        const usuarioResult = await usuarioService.findUsuarioById(uuid);
        set({
          usuario: usuarioResult,
        });
      } catch (e) {
        set({
          usuario: null,
          errorUsuarios: e,
        });
      } finally {
        set({ loadingUsuarios: false });
      }
    },
    findUsuarioByName: async (name: UsuarioInterfaceFilter) => {
      set({ loadingUsuarios: true });
      try {
        const usuariosResult = await usuarioService.findUsuarioByName(name);

        set({
          usuarios: usuariosResult
        });
      } catch (e) {
        set({
          usuarios: [],
          errorUsuarios: e,
        });
      } finally {
        set({ loadingUsuarios: false });
      }
    },

    createUsuario: async (data: UsuarioInterfacePost) => {
      set({ loadingUsuarios: true });

      try {
        const result = await usuarioService.createUsuario(data);
        set({
          usuarios: [...get().usuarios, result],
        });
        return result;
      } catch (e) {
        set({
          errorUsuarios: e,
        });
        return e;
      } finally {
        set({
          loadingUsuarios: false,
        });
      }
    },

    updateUsuario: async (data: UsuarioInterfacePatch) => {
      set({ loadingUsuarios: true });

      try {
        const result = await usuarioService.updateUsuario(data);
        const updatedUsuarios = get().usuarios.map((usuario) => usuario.uuid !== data.uuid ? usuario : {
          ...usuario,

        });

        set({
          usuarios: updatedUsuarios
        })
        return result;
      } catch (e) {
        set({
          errorUsuarios: e,
        });
        return e;
      } finally {
        set({
          loadingUsuarios: false,
        });
      }
    },
    deleteUsuario: async (uuid: string) => {
      set({ loadingUsuarios: true });
      try {
        const result = await usuarioService.deleteUsuario(uuid);
        const updatedUsuarios = get().usuarios.filter((usuario) => usuario.uuid !== uuid);

        set({
          usuarios: updatedUsuarios
        })

        return result;
      } catch (e) {
        set({
          errorUsuarios: e,
        });
        return e;
      } finally {
        set({
          loadingUsuarios: false,
        });
      }
    }
  }))
);
