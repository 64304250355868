import fetch from 'auth/FetchInterceptor';
import { LoginServiceInterface, LoginInterface } from 'types/auth.interface';

const login = async (data: LoginInterface) => {
  const result = await fetch({
    url: '/auth/login',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
  return result.data;
};

// const signUp = async data => {
//   const result = await fetch({
//     url: '/auth/signup',
//     method: 'post',
//     headers: {
//       'public-request': 'true',
//     },
//     data: data,
//   });

//   return result.data;
// };

const authService: LoginServiceInterface = {
  login,
  // signUp,
};

export default authService;
