import fetch from 'auth/FetchInterceptor';
const { default: axios } = require('axios');

const getCalendarList = async accessToken => {
  const userInfo = await axios
    .get('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then(res => res.data);

  return userInfo?.items;
};

const getEventList = async (accessToken, haveEmailSync) => {
  const firstDayOfMonth = new Date();
  firstDayOfMonth.setDate(1);
  firstDayOfMonth.setHours(0, 0, 0, 0);
  const lastDayOfMonth = new Date();
  lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
  lastDayOfMonth.setDate(0);

  const userInfo = await axios
    .get(
      `https://www.googleapis.com/calendar/v3/calendars/${haveEmailSync}/events?timeMin=${firstDayOfMonth.toISOString()}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    .then(res => res.data);

  return userInfo?.items;
};

const saveCalendar = async data => {
  const result = await fetch({
    url: `/usuario/${data.id}`,
    method: 'patch',
    data,
  });
  return result.data;
};

const googleCalendarService = {
  getCalendarList,
  saveCalendar,
  getEventList,
};

export default googleCalendarService;
