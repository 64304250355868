import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { THEME_CONFIG } from './configs/AppConfig';
import { Avatar } from 'antd';
import { GoogleOAuthProvider } from '@react-oauth/google';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID}
      >
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point"
        >
          <Router>
            <Switch>
              <Route
                path="/"
                component={Views}
              />
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
